// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.clasificacion {
    width: 60%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 10px;
    margin-top: 50px;
}

h1 {
  text-align: center;
  margin-top: 0;
}

.clasificacion > table {
    width: 100%;
}

td {
    text-align: center;
}`, "",{"version":3,"sources":["webpack://./src/components/Clasificacion.css"],"names":[],"mappings":"AAAA;IACI,UAAU;IACV,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,gBAAgB;AACpB;;AAEA;EACE,kBAAkB;EAClB,aAAa;AACf;;AAEA;IACI,WAAW;AACf;;AAEA;IACI,kBAAkB;AACtB","sourcesContent":[".clasificacion {\r\n    width: 60%;\r\n    padding: 10px;\r\n    border: 1px solid #ccc;\r\n    border-radius: 10px;\r\n    margin-top: 50px;\r\n}\r\n\r\nh1 {\r\n  text-align: center;\r\n  margin-top: 0;\r\n}\r\n\r\n.clasificacion > table {\r\n    width: 100%;\r\n}\r\n\r\ntd {\r\n    text-align: center;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
