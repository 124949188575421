// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.allGPs {
    width: 80%;
}

.gpTitle {
    border: 1px solid #ccc;
    border-radius: 10px;
    margin-top: 50px;
    display: flex;
    cursor: pointer;
}

.jornada {
    margin-left: 25px;
    margin-right: auto;
}

.pais {
    margin-right: auto;
}

.gpInfo {
    padding: 10px;
    width: calc(.gpTitle.width);
    display: flex;
    flex-direction: column;
    justify-content: space-around;

    div {
        display: flex;
        justify-content: space-between;
    }
}

table {
    border-collapse: collapse;
}

.parrilla {
    border: 2px solid white;
}

.time {
    background-color: white;
}

.hotlap-icon {
    margin: 3px 0 0 2px;
    height: 20px;
    width: 20px;
}

.gpPositions {
    display: flex;
    justify-content: space-around;
    margin-top: 10px;
    margin-bottom: 20px;
}

.gpPositions > table {
    width: 40%;
}

.gpMap {
    width: calc(.gpInfo.width);
    margin: 10px auto 0 auto  ;
}

.gpMap > img {
    width: 100%;
    border-radius: 10px;
}`, "",{"version":3,"sources":["webpack://./src/components/AllGPs.css"],"names":[],"mappings":"AAAA;IACI,UAAU;AACd;;AAEA;IACI,sBAAsB;IACtB,mBAAmB;IACnB,gBAAgB;IAChB,aAAa;IACb,eAAe;AACnB;;AAEA;IACI,iBAAiB;IACjB,kBAAkB;AACtB;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI,aAAa;IACb,2BAA2B;IAC3B,aAAa;IACb,sBAAsB;IACtB,6BAA6B;;IAE7B;QACI,aAAa;QACb,8BAA8B;IAClC;AACJ;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,uBAAuB;AAC3B;;AAEA;IACI,uBAAuB;AAC3B;;AAEA;IACI,mBAAmB;IACnB,YAAY;IACZ,WAAW;AACf;;AAEA;IACI,aAAa;IACb,6BAA6B;IAC7B,gBAAgB;IAChB,mBAAmB;AACvB;;AAEA;IACI,UAAU;AACd;;AAEA;IACI,0BAA0B;IAC1B,0BAA0B;AAC9B;;AAEA;IACI,WAAW;IACX,mBAAmB;AACvB","sourcesContent":[".allGPs {\r\n    width: 80%;\r\n}\r\n\r\n.gpTitle {\r\n    border: 1px solid #ccc;\r\n    border-radius: 10px;\r\n    margin-top: 50px;\r\n    display: flex;\r\n    cursor: pointer;\r\n}\r\n\r\n.jornada {\r\n    margin-left: 25px;\r\n    margin-right: auto;\r\n}\r\n\r\n.pais {\r\n    margin-right: auto;\r\n}\r\n\r\n.gpInfo {\r\n    padding: 10px;\r\n    width: calc(.gpTitle.width);\r\n    display: flex;\r\n    flex-direction: column;\r\n    justify-content: space-around;\r\n\r\n    div {\r\n        display: flex;\r\n        justify-content: space-between;\r\n    }\r\n}\r\n\r\ntable {\r\n    border-collapse: collapse;\r\n}\r\n\r\n.parrilla {\r\n    border: 2px solid white;\r\n}\r\n\r\n.time {\r\n    background-color: white;\r\n}\r\n\r\n.hotlap-icon {\r\n    margin: 3px 0 0 2px;\r\n    height: 20px;\r\n    width: 20px;\r\n}\r\n\r\n.gpPositions {\r\n    display: flex;\r\n    justify-content: space-around;\r\n    margin-top: 10px;\r\n    margin-bottom: 20px;\r\n}\r\n\r\n.gpPositions > table {\r\n    width: 40%;\r\n}\r\n\r\n.gpMap {\r\n    width: calc(.gpInfo.width);\r\n    margin: 10px auto 0 auto  ;\r\n}\r\n\r\n.gpMap > img {\r\n    width: 100%;\r\n    border-radius: 10px;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
